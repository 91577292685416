<template>
  <div class="pb-5">
    <div class="pt-0" hide-footer dense>
      <ResultsOverview :is-public="isPublic" can-share :essay="essay" />
    </div>
    <div>
      <div class="mt-4 result-content">
        <ResultByCategory :essay="essay" class="mb-4" />

        <div class="essay-tabs-container">
          <VTabs
            v-model="tab"
            :next-icon="$icons.arrowRight"
            :prev-icon="$icons.arrowLeft"
            :show-arrows="true"
            class="essay-tabs"
            color="new"
          >
            <VTab>
              <img class="essay-tab-icon" src="/images/icons/marking.svg" />
              <b>Redação</b>
            </VTab>
            <VTab>
              <img class="essay-tab-icon" src="/images/icons/star.png" />
              <b>Comentários</b>
            </VTab>
            <VTab>
              <img class="essay-tab-icon" src="/images/icons/stats.png" />
              <b>Métricas</b>
            </VTab>
            <VTab>
              <img class="essay-tab-icon" src="/images/icons/themes.png" />
              <b>Tema</b>
            </VTab>
          </VTabs>

          <VTabsItems v-model="tab" class="EssayItemTabs">
            <VTabItem :key="0" :value="0">
              <TextArea
                :initial-marks="marks"
                :is-subscribed="isSubscribed"
                :value="correction.essayContent"
              />
            </VTabItem>
            <VTabItem :key="1" :value="1">
              <div v-if="!isPublic" class="correction-comment pa-5">
                <CommentWall v-if="!isSubscribed" />
                <div>
                  <!-- <VBtn @click="loadComment">
                    REdo
                  </VBtn> -->
                  <div
                    v-if="loadingComment && isSubscribed"
                    class="loading-comment"
                  >
                    <VAlert
                      border="top"
                      colored-border
                      color="new"
                      elevation="2"
                      class="subtitle"
                    >
                      Estamos preparando os comentários, isso pode levar alguns
                      segundos. Por favor, aguarde.<br />
                      <AppLoading />
                    </VAlert>
                  </div>
                  <div>
                    <div
                      ref="comment"
                      class="comment-box"
                      v-html="formatedComment"
                    ></div>
                  </div>
                </div>
              </div>
              <div v-else class="correction-comment pa-5">
                <div
                  v-if="comment"
                  ref="comment"
                  class="comment-box"
                  v-html="formatedComment"
                ></div>
                <div v-else>
                  <VAlert color="blue" dark>
                    Essa correção foi feita por um usuário que não é assinante e
                    não possui comentários.
                  </VAlert>
                </div>
              </div>
            </VTabItem>
            <VTabItem :key="2" :value="2">
              <EssayStats
                v-if="!isPublic || isSubscribed"
                :statistics="correction.statistics"
                :is-free-theme="essay.themeId === 'tema-livre'"
                class="theme-box"
              />
              <StatsWall v-if="!isSubscribed && !isPublic" />
              <div v-if="!isSubscribed && isPublic" class="pa-5">
                <VAlert color="blue" dark>
                  Essa correção foi feita por um usuário que não é assinante e
                  não tem acesso às métricas.
                </VAlert>
              </div>
            </VTabItem>
            <VTabItem :key="3" :value="3">
              <div v-if="loadingTheme || !theme">
                {{ theme }}
              </div>
              <div v-else class="theme-box comment-box pa-5">
                <h1>
                  {{ theme.title }}
                </h1>
                <div v-html="theme.html" />
              </div>
            </VTabItem>
          </VTabsItems>
        </div>
      </div>
    </div>
    <ThemeEvasionWall
      v-if="evasion.isEvasion"
      :is-evaded="evasion.isEvasion"
      :is-complete-evasion="evasion.isCompleteEvasion"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import CommentWall from '../paywalls/CommentWall.vue'
import AppLoading from '../app/AppLoading.vue'
import placeholderComment from '../../constants/placeholderComment'
import StatsWall from '../paywalls/StatsWall.vue'
import ResultsOverview from './ResultsOverview.vue'
import ResultByCategory from './ResultByCategory.vue'
import TextArea from './CorrectionText/TextArea.vue'
import ThemeEvasionWall from './ThemeEvasionWall.vue'
import EssayStats from './EssayStats.vue'
import ContentAPI from '~/plugins/ghost'

export default {
  components: {
    ResultsOverview,
    ResultByCategory,
    TextArea,
    CommentWall,
    ThemeEvasionWall,
    AppLoading,
    EssayStats,
    StatsWall
  },
  props: {
    essay: {
      type: Object,
      required: true
    },
    isPublic: Boolean
  },
  data() {
    return {
      tab: 0,
      rating: 0,
      loading: true,
      loadingImage: true,
      confirm: false,
      submiting: false,
      imageCorrectionData: null,
      loadingComment: false,
      theme: null,
      loadingTheme: true,
      comment: '',
      evasion: {
        isEvasion: false,
        isCompleteEvasion: false
      }
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    marks() {
      return this.correction ? this.correction.marks : []
    },

    isSubscribed() {
      if (this.essay?.user?.subscriptionDate) {
        return dayjs(this.essay?.user?.subscriptionDate).isAfter(dayjs())
      }

      return this.$store.getters['auth/isSubscribed']
    },

    ogComment() {
      return this.isSubscribed ? this.comment : placeholderComment
    },

    formatedComment() {
      if (!this.ogComment) return ''

      const comps = {
        1: 'https://coredacao.com/conteudo/8-dicas-para-conseguir-nota-maxima-na-competencia-1-do-enem/',
        2: 'https://coredacao.com/conteudo/5-passos-para-garantir-200-pontos-na-competencia-2-do-enem/',
        3: 'https://coredacao.com/conteudo/o-segredo-para-conquistar-nota-maxima-na-competencia-3/',
        4: 'https://coredacao.com/conteudo/competencia-4-o-que-fazer-para-alcancar-nota-maxima-no-enem/',
        5: 'https://coredacao.com/conteudo/tudo-o-que-voce-precisa-saber-sobre-a-competencia-5-do-enem/'
      }

      const replacements = {
        conectivos: 'https://coredacao.com/conteudo/conectivos-para-redacao/',
        argumentos:
          'https://coredacao.com/conteudo/5-tipos-de-argumento-que-voce-precisa-conhecer/',
        argumentação:
          'https://coredacao.com/conteudo/5-tipos-de-argumento-que-voce-precisa-conhecer/',
        repertório:
          'https://coredacao.com/conteudo/guia-infalivel-para-melhorar-seu-repertorio-sociocultural/'
      }

      let comment = this.ogComment
      // Make everything in quotes(") italic
      comment = comment.replace(/"([^"]+)"/g, '"<b>$1</b>"')

      // Replace all from replacements into links
      Object.keys(replacements).forEach((key) => {
        comment = comment.replace(
          new RegExp(key, 'g'),
          `<a href="${replacements[key]}" target="_blank">${key}</a>`
        )
      })

      comment = comment
        .split('\n')
        .filter((line) => !!line.trim())
        .map((line) => {
          if (line.trim().endsWith(':')) {
            if (line.toLowerCase().includes('competência')) {
              // Extract number from line
              const numbers = line.match(/\d+/)

              if (!numbers) return line + '<br>'

              const number = parseInt(numbers[0])

              if (number > 0 && number < 6) {
                return `<h4><a href="${comps[number]}" target="_blank">${line}</a></h4>`
              }
            }

            return `<h4>${line.trim()}</h4>`
          }

          return line + '<br>'
        })
        .join('')

      return comment
    },

    correction() {
      return this.essay?.corrections[0]
    }
  },

  watch: {
    tab(v) {
      if (v === 1) {
        if (this.isSubscribed) {
          this.loadComment()
        }
      }
    },
    correction() {
      this.checkEvasion()
    }
  },

  mounted() {
    this.comment = this.correction?.comment?.slice() || ''
    this.loadingComment = !this.comment

    this.$nextTick(() => {
      this.handleClicks()
      this.checkEvasion()
      this.loadTheme()
    })
  },

  methods: {
    copyText(text) {
      window.navigator.clipboard.writeText(text)
    },
    async loadTheme() {
      try {
        this.theme = await ContentAPI.posts.read(
          { slug: this.essay.theme.slug },
          {
            formats: ['html', 'plaintext']
          }
        )
        this.loadingTheme = false
      } catch (e) {
        console.log(e)
      }
    },
    checkEvasion() {
      if (this.correction) {
        const marks = this.correction.marks

        const partial = !!marks.find((m) => m.id === 'FUGA-TEMA-PARCIAL')
        const full = !!marks.find((m) => m.id === 'FUGA-TEMA-COMPLETA')

        this.evasion = {
          isEvasion: partial || full,
          isCompleteEvasion: full
        }
      }
    },
    async loadComment(force = false) {
      if (!force && !!this.comment) {
        return
      }

      this.loadingComment = true
      try {
        const { data } = await this.$axios.get(
          `/correction/comment/${this.correction.id}`,
          {
            loading: false
          }
        )

        this.comment = data.comment
      } catch (e) {
        if (e?.response?.status === 500) {
          setTimeout(() => {
            this.loadComment()
          }, 1000)
        }
      }

      this.handleClicks()

      this.loadingComment = false
    },

    sleep(ms = 500) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, ms)
      })
    },

    async handleClicks() {
      let rendered = false

      while (!rendered) {
        if (this.$refs.comment) {
          rendered = true
        }

        if (!rendered) {
          await this.sleep()
        }
      }

      this.$nextTick(() => {
        const links = Array.from(this.$refs.comment.querySelectorAll('a'))

        links.forEach((link) => {
          const href = link.href
          const isExternal = !href.includes('coredacao')
          if (isExternal) {
            link.target = '_blank'
          }
          link.addEventListener('click', (e) => {
            if (!isExternal) {
              e.preventDefault()

              const slug = href
                .split('/')
                .filter((p) => !!p)
                .pop()

              this.$root.$emit('openContent', {
                slug,
                hideTheme: true
              })
            }
          })
        })
      })
    }
  }
}
</script>

<style lang="sass">
.EssayItemTabs
  overflow: auto !important
.loading-comment
  max-width: 400px
  margin: 0 auto
  text-align: center
.theme-box
  img
    max-width: 100%
    margin-bottom: 10px
.comment-box
  font-size: 18px
  h4
    margin-top: 10px

.essay-tab-icon
  height: 20px
  margin-right: 6px
.essay-tabs-container
  border: thin solid #9e9e9e
  border-radius: 14px
  overflow: hidden
  .essay-tabs
    border-bottom: thin solid #9e9e9e
.saved-alert
  img
    height: 28px
  .VBtn
    img
      height: 14px
.info-marks
  span
    font-size: 14px
  .info-icon
    position: relative
    margin-right: 6px
.essay-image
  max-width: 100%
  background-color: white
  border: thin solid #9e9e9e
  .essay-image__content
    min-width: 800px
.comments
  white-space: pre-line
  font-size: 16px
.result
  padding-bottom: 120px
  h4
    margin-top: 25px
  .result-content
    position: relative
    z-index: 4

@media screen and (max-width: 600px)
  .saved-alert
    flex-direction: column
    text-align: center
    & > span
      margin-bottom: 10px
</style>
